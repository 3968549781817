const env = {
  urls: {
    ORIGINAL: 'https://dsgomsk.space/',
    // PROXY: 'https://corsproxy-9hr4.onrender.com/proxy?url=',
    PROXY: 'api?url=',
    SOURCE: 'https://thesex.dosug-55omsk.net/',
    NEW: 'new' // ждут звонка
  },
}

export default Object.freeze(env)
