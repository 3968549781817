import classes from './Greet.module.scss'
import Overlay from '../../../ui/Overlay/Overlay'
import Box from '../../../ui/Box/Box'
import Button from '../../../ui/Button/Button'
import animations from '../../../../other/animations'
import env from '../../../../other/environment'

const Greet = ({ onOkClick }) => {
  return <>
    <Overlay {...animations.overlay} bg='rgba(0, 0, 0, .9)'>
      <Box
        className={classes.wrapper}
        title={'Привет 🤝'}
        {...animations.greet}
      >
        <div className={classes.description}>
          <p>Это мини-сайтец с анкетами проституток Омска, который парсит страницы с <a href={env.urls.SOURCE}>dosug-55omsk.com</a>, категория «ждут звонка».</p>
          <p>Некоторые регают несколько анкет на один и тот же номер. Если они совпадают, то справа от номера будет указан индекс анкеты с таким же номером.</p>
          <p>Анкету можно добавить в избранное: сохраняются последние четыре цифры номера телефона, так что если есть несколько анкет на одном номере, то они все пойдут в избранное. Все избранные будут в начале списка, если не будешь чистить историю браузера, то порядок будет сохраняться.</p>
          <p>Убрать размытие можно в меню фильтров.</p>
        </div>

        <Button className={classes.okBtn} onClick={onOkClick}>понято</Button>
      </Box>
    </Overlay>
  </>
}

export default Greet
