import classes from './Button.module.scss'

const Button = ({ children, onClick = () => null, className, ...rest }) => {
  return <>
    <button
      onClick={onClick}
      className={`${classes.button} ${className}`}
      {...rest}
    >{children}</button>
  </>
}

export default Button
